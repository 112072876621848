<template>
        <div class="profileBack">
                <div class="profileUp">
                        <div class="profileLeft">
                                <div class="titleProd">ФИО:</div>
                                <input type="text" placeholder="Ваше имя" class="mt15" v-model="name" :class="{error : errfield.indexOf('name') != -1}" />
                                <input type="text" placeholder="Ваша фамилия" class="mt10" v-model="fename" :class="{error : errfield.indexOf('fename') != -1}" />
                                <input type="text" placeholder="Ваше отчество" class="mt10" v-model="oth" />

                                <div class="titleProd mt30">ИЗМЕНЕНИЕ ПАРОЛЯ:</div>
                                <inputPassword placeholder="Старый пароль" ref="psold" class="mt15" ></inputPassword>
                                <inputPassword placeholder="Новый пароль" ref="ps" class="mt10" ></inputPassword>
                                <inputPassword placeholder="Подтвердить новый пароль" ref="ps1" class="mt10" ></inputPassword>
                        </div>
                        <div class="profileRight">
                                <div class="titleProd">ДАННЫЕ:</div>
                                <v-select class="mt15" :options="listProfs" v-model="prof" :clearable="false" placeholder="Роль в приложении"></v-select>
                                <input type="text" placeholder="Название школы" class="mt10" v-model="school" />
                                <v-select class="mt10" :options="listRegions" v-model="region" :clearable="false" placeholder="Субъект РФ"></v-select>
                                <input type="text" placeholder="Населенный пункт" class="mt10" v-model="town" />

                                <div class="titleProd mt30">ЭЛЕКТРОННАЯ ПОЧТА:</div>
                                <input type="text" class="mt20" v-model="email" disabled/>
                        </div>
                </div>
                <div class="flexRow mt40">
                        <buttonRed class="btn" text='Сохранить' @clickButton="save" :disable="isDisable" v-if="isSaved"></buttonRed>
                        <buttonGray class="btn ml10" text='Отменить' @clickButton="close"></buttonGray>
                        <div class="dvError mt20" :class="{viewError : errorText, viewSuccess : saveOk}">{{errorText}}</div>
                </div>
        </div>
</template>

<script>

        import ButtonRed from "../common/ButtonRed"
        import ButtonGray from "../common/ButtonGray"
        import InputPassword from "../common/InputPassword"
        import g from "../globals";

	export default {
                components : {ButtonRed, ButtonGray, InputPassword},

		data : function() {
			return {
                                userData : {},
                                name : "",
                                fename : "",
                                oth : "",
                                email : "",
                                school : "",
                                town : "",
                                prof : null,
                                region : null,
                                listRegions : [],
                                listProfs : [],
                                errfield : [],
                                errorText : null,
                                saveOk : false,
                                saving : false
			}
		},

                created() {
                        this.checkPSExist();
                },

                computed : {
                        isDisable() {
                                if(this.saving == true) return true;
                                if(this.name.trim() !== this.userData.name) return false;
                                if(this.fename.trim() !== this.userData.family) return false;
                                if(this.oth && this.oth.trim() !== this.userData.oth) return false;
                                if(this.school && this.school.trim() !== this.userData.school) return false;
                                if(this.town && this.town.trim() !== this.userData.town) return false;
                                if(this.userData.prof && this.prof && this.userData.prof != this.prof.value) return false;
                                if(this.userData.region && this.region && this.userData.region != this.region.value) return false;

                                if(this.$refs.psold && this.$refs.psold.getPS().trim() !== "") return false;
                                if(this.$refs.ps && this.$refs.ps.getPS().trim() !== "") return false;
                                if(this.$refs.ps1 && this.$refs.ps1.getPS().trim() !== "") return false;
                                return true;
                        },

                        isSaved() {
                                return this.email !== "from_mesh"
                        }
                },

                methods : {
                        checkPSExist() {
                                this.$nextTick(() => {
                                        if(!this.$refs.psold) {
                                                this.checkPSExist();
                                        }
                                        else {
                                                this.userData = JSON.parse(profile);
                                                this.name = this.userData.name;
                                                this.fename = this.userData.family;
                                                this.oth = this.userData.oth || "";
                                                this.email = this.userData.email;
                                                this.school = this.userData.school || "";
                                                this.town = this.userData.town || "";
                                                this.listRegions = JSON.parse(region);
                                                this.listProfs = JSON.parse(prof);
                                                if(findIdx(this.listProfs, this.userData.prof) != -1)
                                                        this.prof = this.listProfs[findIdx(this.listProfs, this.userData.prof)];
                                                if(findIdx(this.listRegions, this.userData.region) != -1)
                                                        this.region = this.listRegions[findIdx(this.listRegions, this.userData.region)];
                                        }
                                })
                        },

                        async save() {
                                this.saving = true;
                                this.saveOk = false;
                                this.errorText = null;
                                this.errfield = [];
                                this.name = this.name.trim();
                                this.fename = this.fename.trim();
                                if(this.name.trim() == "" || this.fename.trim() == "") {
                                        this.errorText = "Поля Имя и Фамилия необходимо заполнить";
                                        if(this.name.trim() == "") this.errfield.push("name");
                                        if(this.fename.trim() == "") this.errfield.push("fename");
                                        this.saving = false;
                                        return;
                                }

                                let param = {
                                        id : this.userData.id,
                                        name : this.name,
                                        family : this.fename,
                                        oth : this.oth,
                                        school : this.school,
                                        town : this.town,
                                        psOld : this.$refs.psold.getPS(),
                                        ps : this.$refs.ps.getPS(),
                                        ps1 : this.$refs.ps1.getPS()
                                }
                                if(this.region) param.region = this.region.value
                                if(this.prof) param.prof = this.prof.value

                                try {
                                        let data = await g.postUrl("auth/saveData", param);
                                        this.saveOk = true;
                                        this.errorText = "Профиль сохранен успешно";
                                        this.userData = param;
                                        this.$refs.psold.clear();
                                        this.$refs.ps.clear();
                                        this.$refs.ps1.clear();
                                } catch (e) {
                                        this.errorText = e.message;
                                }
                                this.saving = false;
                        },

                        close() {
                                if(window.parent && window.parent.closeProfile) {
                                        window.parent.closeProfile();
                                }
                        }
                }
	}

</script>
